@import "./src/mypage/scss/tek-settings.scss";



















































































































.blogs-landingpage {
    &__tab-content-promoarea {
        @include media-breakpoint-down(md){
            margin-bottom: 3rem;
        }
        margin-bottom: 3.875rem;
    }

    .blog-promo-block:first-of-type{
        @include media-breakpoint-down(md){
            margin-bottom: 1.75rem;
        }
    }

    &__tab-content-promoarea {
        .col-12:first-of-type {
            @include media-breakpoint-down(md){
                margin-bottom: 1.75rem;
            }
        }

        .promotion-image-wrapper{
            display: none!important;
        }
    }
}
